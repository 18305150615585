import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
    name: 'MilestoneTemplateDialog',
    components: {
        CustomDialog
    },

    props: {
        value: { type: Boolean, default: false },
        isEditDialog: { type: Boolean, default: false },
        title: String,
        fieldsToEdit: Object
    },
    computed: {
        disabled_btn() {
            return !this.name ? true : false
        }
    },
    data: () => ({
        open: false,
        name: null,
        is_public: false,
        status: 'active'
    }),

    watch: {
        value: {
            handler: function(new_val) {
                this.open = new_val
                if (new_val && !this.fieldsToEdit) {
                    this.name = null
                    this.is_public = false
                    this.status = 'active'
                }
            },
            immediate: true
        },
        open(new_val) {
            this.$emit('input', new_val)
        },
        fieldsToEdit: {
            handler: function(new_val) {
                this.is_public = new_val ? new_val.public : false
                this.name = new_val ? new_val.name : null
                this.status = new_val ? new_val.status : 'active'
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        cancel() {
            this.open = false
        },
        save() {
            const fields_to_save = {
                name: this.name,
                status: this.status,
                is_public: this.is_public
            }
            this.$emit('save', fields_to_save)
        },

        update_fields({ fields }) {
            const new_fields = Object.assign({}, fields)
            this.name = new_fields.name
            this.status = new_fields.status
            this.is_public = new_fields.public ? true : false
        }
    }
}
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
//components
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import MilestoneDialog from '@/modules/Milestone/components/MilestoneDialog/MilestoneDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import MilestoneTemplateDialog from '@/modules/MilestoneTemplate/components/MilestoneTemplateDialog/MilestoneTemplateDialog.vue'
import ViewReportTemplate from './components/ViewReportTemplate.vue'
import ViewRequestTemplate from './components/ViewRequestTemplate.vue'

export default {
    name: 'Templates',
    mixins: [fetch_services],
    components: {
        VueTable,
        Actions,
        MilestoneDialog,
        DeleteDialog,
        MilestoneTemplateDialog,
        ViewReportTemplate,
        ViewRequestTemplate
    },

    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Templates', disabled: true, route: null }
        ],
        headers: [
            { text: 'Template Type', value: 'replica_type', width: 150 },
            { text: 'Name', value: 'name' },
            {
                text: 'Is Public',
                value: 'public',
                align: 'center'
            },
            {
                text: 'Milestones',
                value: 'milestones_count',
                width: 120,
                align: 'center'
            },
            { text: 'Added By', width: 99, sortable: false },
            { text: 'Status', value: 'status', width: 100 },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
                sortable: false,
                width: 150
            }
        ],
        type: 'all',
        milestone_add_dialog: false,
        milestone_edit_dialog: false,
        milestone_delete_dialog: false,
        report_delete_dialog: false,
        report_view_dialog: false,
        clientrequest_view_dialog: false,
        clientrequest_delete_dialog: false,
        invoice_template_view_dialog: false
    }),
    filters: {
        remove_app(text) {
            return text
                .replace('App\\', '')
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, function(str) {
                    return str.toUpperCase()
                })
        }
    },
    created() {
        this.loadItems(this.dynamic_api, true, null, null)

        // this.$event.$on('show-invoice-template-guide', () => {
        //   this.open_help_dialog()
        // })
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    computed: {
        dynamic_api() {
            return `api/template/all?type=${this.type}`
        }
    },
    methods: {
        getType(item) {
            return item.replica_type.replace('App\\', '').toLowerCase()
        },
        showByType(type) {
            this.type = type
            this.loadItems(this.dynamic_api, true, null, null)
        },
        open_template_add_dialog(type) {
            if (type === 'milestone') {
                this.milestone_add_dialog = true
                this.milestone_edit_dialog = false
            } else if (type === 'clientrequest') {
                this.$router.push({ name: 'builders-request--template' })
            } else {
                //report
                this.$router.push({ name: 'builders-report--template' })
            }
        },
        open_template_delete_dialog(item) {
            let type = this.getType(item)
            this.activeItem = item
            if (type === 'milestone') {
                this.milestone_delete_dialog = true
            } else if (type === 'clientrequest') {
                this.clientrequest_delete_dialog = true
            } else {
                //report
                this.report_delete_dialog = true
            }
        },
        open_template_edit_dialog(item) {
            let type = this.getType(item)
            this.activeItem = item
            if (type === 'milestone') {
                this.milestone_edit_dialog = true
                this.milestone_add_dialog = false
            } else if (type === 'clientrequest') {
                this.$router.push({
                    name: 'builders-request--template',
                    params: { id: item.id }
                })
            } else {
                //report
                this.$router.push({
                    name: 'builders-report--template',
                    params: { id: item.id }
                })
            }
        },
        open_template_view(item) {
            let type = this.getType(item)
            if (type === 'milestone') {
                this.clientrequest_view_dialog = false
                this.request_view_dialog = false
                this.$router.push({
                    name: 'templates/milestone',
                    params: { id: item.id }
                })
            } else if (type === 'invoice') {
                this.clientrequest_view_dialog = false
                this.report_view_dialog = false
            } else if (type === 'clientrequest') {
                this.report_view_dialog = false
                this.clientrequest_view_dialog = true
            } else {
                //report
                this.clientrequest_view_dialog = false
                this.report_view_dialog = true
            }
            this.activeItem = item
        },
        handleAddMilestone(payload) {
            this.addItem(
                `api/template`,
                payload,
                'Milestone template created',
                () => (this.milestone_add_dialog = false)
            )
        },
        handleEditMilestone(payload) {
            this.updateItem(
                `api/template/${this.activeItem.id}`,
                payload,
                'Milestone template updated',
                () => (this.milestone_edit_dialog = false)
            )
        },
        handleDeleteMilestone(payload) {
            this.deleteItem(
                `api/template/milestones/${this.activeItem.id}`,
                'Milestone deleted',
                () => (this.milestone_delete_dialog = false)
            )
        },
        handleDeleteReport(payload) {
            this.deleteItem(
                `api/template/reports/${this.activeItem.id}`,
                'Report deleted',
                () => (this.report_delete_dialog = false)
            )
        },
        handleDeleteClientRequest(payload) {
            this.deleteItem(
                `api/template/requests/${this.activeItem.id}`,
                'Client request deleted',
                () => (this.clientrequest_delete_dialog = false)
            )
        }
    }
}
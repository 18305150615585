<template>
  <CustomDialog
    :title="title"
    v-model="open"
    :has-footer="false"
    max-width="900"
  >
    <template v-slot:content>
      <Preview :questions="structures" v-if="structures.length" />
      <Empty v-else headline="Template structures not found!"></Empty>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import Preview from '@/modules/Forms-Builder/components/Builder/Preview.vue'

export default {
  name: 'ViewRequestTemplate',
  components: {
    CustomDialog,
    Preview
  },
  props: {
    title: { type: String, default: 'Request Template' },
    item: Object,
    value: { type: Boolean, default: false }
  },

  data: () => ({
    open: false
  }),
  computed: {
    structures() {
      return this.item && this.item.template ? this.item.template : []
    }
  },
  watch: {
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    closeDialog() {
      this.open = false
    }
  }
}
</script>

<style scoped></style>
